<template>
    <div class="tab-text">
        <div class="vx-row w-1/2 mb-6 mt-3 ml-5">
            <vs-button
                class="mt-2"
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-plus"
                @click="handleCreate()"
            >Create</vs-button>
        </div>
        <div class="vx-col w-1/2 mb-6 mt-3 ml-5">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Warehouse</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Warehouse @selected="(val) => { this.selectedWarehouse = val }"></Warehouse>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/4 w-full">
                    <span>Transaction Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-input class="w-full" v-model="date" type="date" v-validate="'required'"/>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-1/4 w-full">
                    <span></span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button
                        @click="handleFilter()"
                        size="medium"
                        color="primary"
                        icon-pack="feather"
                        icon="icon-search"
                        title="Filter"
                    >Filter</vs-button>
                </div>
            </div>
        </div>

        <div class="vx-row mb-12">
            <div class="core vx-col md:w-1/1 w-full mb-base">
                <data-table
                    :draw="draw"
                    :baseUrl="baseUrl"
                    :status="0"
                    :warehouse="this.selectedWarehouse"
                    :date="date"
                ></data-table>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "../DataTable";
import Warehouse from "../component/WarehouseV2.vue";

export default {
    components: {
        DataTable,
        Warehouse,
    },
    data() {
        return {
            baseUrl: "api/wms/v1/inter-storage",
            draw: 0,
            date: "",
            selectedWarehouse: [],
        };
    },
    methods: {
        handleCreate() {
            this.$router.push({
                name: "inter-storage-create",
            });
        },
        handleFilter() {
            this.draw++;
        },
    },
};
</script>